import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { tapClickAnalytics } from '../../analystic';
import { useGetAllCardsQuery, useGetCardDetailsQuery } from '../../api';
import Container from '../../components/Container/Container';
import Header from '../../components/Header/Header';
import Spinner from '../../components/Spinner/Spinner';
import { Card } from '../../types';
import styles from './CardDetails.module.css';
import LuckScore from './components/LuckScore/LuckScore';
import TapCard from './components/TapCard';
import TapComponent from './components/TapComponent';
import WhiteBitBanner from './components/WhiteBitBanner';
import { PLAYER_DETAILS } from './constants';
import BackButton from '../../components/BackButton';

const CardDetails = (): ReactElement => {
  const navigate = useNavigate();
  const { cardId } = useParams();
  const { data: cards, isFetching: areCardsFetched } = useGetAllCardsQuery();
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const { data: cardDetails, isFetching: areDetailsFetched } = useGetCardDetailsQuery(cardId as string, {
    skip: !currentCard || !currentCard.unlocked,
  });
  const [openedTapSession, setOpenedTapSession] = useState<boolean>(false);
  const [tapCount, setTapCount] = useState<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const { height, weight, dateOfBirth, city } = PLAYER_DETAILS[cardId ? Number(cardId) : 1];

  useEffect(() => {
    if (cards) {
      const [card] = cards.filter(({ card }) => card.id === Number(cardId));

      if (card && card.unlocked) {
        setCurrentCard(card);
      } else {
        navigate('/');
      }
    }
  }, [cards, cardId]);

  if (areCardsFetched || !currentCard || areDetailsFetched) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  const { tshirtNumber, position, name, id, type } = currentCard.card;
  const [firstName, lastName] = name.split(' ');

  const handleSetTapCount = (value: number) => {
    setTapCount(value);
  };

  const onOpenTapSession = () => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }

    setOpenedTapSession(true);
    setTapCount(tapCount + 1);
    tapClickAnalytics();
  };

  const onCloseTapSession = () => {
    setOpenedTapSession(false);
  };

  return (
    <>
      <BackButton />
      <Container isBlurComponent={openedTapSession} innerRef={ref}>
        <Header>
          <div className={styles.emptyBlock} />
          <LuckScore score={cardDetails?.luckScore} />
        </Header>
        <TapCard id={id} type={type} openedTapSession={openedTapSession} handleOpenTapSession={onOpenTapSession} />
        <div className={styles.playerWrapper}>
          <div className={styles.playerInfo}>
            <div>
              <p className={styles.name}>{firstName}</p>
              <p className={styles.name}>{lastName}</p>
            </div>
            <span className={styles.number}>{tshirtNumber || '-'}</span>
          </div>
          <p className={styles.position}>{position}</p>
          <table className={styles.playerInfoDetails}>
            <tbody>
              <tr>
                <td>Зріст</td>
                <td>{height}</td>
              </tr>
              <tr>
                <td>Вага</td>
                <td>{weight}</td>
              </tr>
              <tr>
                <td>Дата народження</td>
                <td>{dateOfBirth}</td>
              </tr>
              <tr>
                <td>Місто</td>
                <td>{city}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <WhiteBitBanner />
      </Container>
      <TapComponent
        cardId={id}
        type={type}
        tapCount={tapCount}
        openedTapSession={openedTapSession}
        handleSetTapCount={handleSetTapCount}
        onCloseTapSession={onCloseTapSession}
      />
    </>
  );
};

export default CardDetails;
