import cn from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import api from '../../api';
import GetPlayersCard from '../../assets/images/getPlayersCard.png';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import Popup from '../../components/Popup';
import { GET_FIRST_PLAYER_CARD, routes } from '../../consts';
import { AppDispatch, RootState } from '../../store';
import { setShowedEmptyState } from '../../store/showedEmptyCards';
import Preloader from '../Preloader';
import styles from './Collection.module.css';
import AlbumBanner from './components/AlbumBanner';
import CardItem from './components/CardItem/CardItem';
import SettingsLink from './components/SettingsLink/SettingsLink';

const Collection = (): ReactElement => {
  const dispatch = useDispatch() as AppDispatch;
  const { status, data } = useSelector(api.endpoints.getAllCards.select());
  const showedEmptyCards = useSelector<RootState>((state) => state.showedEmptyCards.value);
  const [showModal, setShowModal] = useState(false);

  const unlockedCardsCount = useMemo(() => data?.filter(({ unlocked }) => unlocked).length, [data]);

  const toggleShowModal = (): void => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (status === 'uninitialized') {
      setTimeout(() => dispatch(api.endpoints.getAllCards.initiate()), 2000);
    }
  }, []);

  useEffect(() => {
    if (status === 'fulfilled' && unlockedCardsCount === 0 && !showedEmptyCards) {
      toggleShowModal();
      dispatch(setShowedEmptyState(true));
    }
  }, [status, unlockedCardsCount, showedEmptyCards]);

  if (status === 'uninitialized' || status === 'pending') {
    return <Preloader />;
  }

  const mainCards = data && [...data].slice(0, 28);
  const virtualCards = data && [...data].slice(28).map((item) => ({ ...item, card: { ...item.card, photoUrl: '' } }));

  return (
    <>
      <Container>
        <Header>
          <div className={styles.title}>Збірна 2024</div>
          <SettingsLink />
        </Header>
        <AlbumBanner />
        <div className={cn(styles.additionalBlock, styles.marginTop)}>
          <div>Основна колекція</div>
          <div>({mainCards?.length || 0} карток)</div>
        </div>
        <div className={styles.list}>
          {(mainCards || [])
            .sort((a, b) => Number(b.unlocked) - Number(a.unlocked))
            .sort((a, b) => a.card.id - b.card.id)
            .map((card) => (
              <div key={card.card.id} onClick={() => !card.unlocked && toggleShowModal()}>
                <Link
                  to={card.unlocked ? `${routes.cards}/${card.card.id}` : routes.cardsCollection}
                  className={!card.unlocked ? styles.disabledLink : ''}
                >
                  <CardItem card={card} />
                </Link>
              </div>
            ))}
        </div>
        <div className={styles.additionalBlock}>
          <div>Додаткова колекція</div>
          <div>({virtualCards?.length || 0} картки)</div>
        </div>
        <div className={cn(styles.list, styles.paddingBottom)}>
          {(virtualCards || [])
            .sort((a, b) => Number(b.unlocked) - Number(a.unlocked))
            .map((card) => (
              <div key={card.card.id}>
                <Link
                  to={card.unlocked ? `${routes.cards}/${card.card.id}` : routes.cardsCollection}
                  className={!card.unlocked ? styles.disabledLink : ''}
                >
                  <CardItem card={card} />
                </Link>
              </div>
            ))}
        </div>
        <Footer />
      </Container>
      <Popup
        isOpen={showModal}
        onClose={toggleShowModal}
        imageSource={GetPlayersCard}
        titleText="Як отримати картку?"
        messageText={GET_FIRST_PLAYER_CARD}
      />
    </>
  );
};

export default Collection;
