import React, { ReactElement } from 'react';

import { Achievement as AchievementType, AchievementTypes, GameDetails } from '../../../../types';
import styles from './Achievement.module.css';
import CollectorsCounterBlock from './components/CollectorsCounterBlock';
import GiftBlock from './components/GiftBlock';
import GiftButton from './components/GiftButton';
import MasterOfLuck from './components/MasterOfLuck';
import TitleAndDescriptionBlock from './components/TitleAndDescriptionBlock';
import WinnersOfLuck from './components/WinnersOfLuck';
import cn from 'classnames';

export type AchievementProps = AchievementType & {
  game?: GameDetails;
  userNames?: Record<string, string>;
};

const Achievement = ({
  title,
  type,
  description,
  metadata,
  completed = false,
  rewardUrl,
  game,
  userNames,
}: AchievementProps): ReactElement | null => {
  const isCollectorType = type === AchievementTypes.ALL_CARDS_COLLECTED;
  const isLuckyType = type === AchievementTypes.GREATEST_LUCK_COLLECTED;
  const isWelcomeBonus = type === AchievementTypes.WELCOME_BONUS;

  const eventIsActive = (game?.eventDaysLeft ?? -1) >= 0;
  const gameIsActive = (game?.gameDaysLeft ?? -1) >= 0;

  if (isWelcomeBonus && !eventIsActive) {
    return null;
  }

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.collectorWrapper]: isCollectorType,
      })}
      data-testid="achievement"
    >
      <TitleAndDescriptionBlock
        title={title}
        description={description}
        type={type}
        game={game}
        completed={completed}
        eventIsActive={eventIsActive}
        gameIsActive={gameIsActive}
      />
      <CollectorsCounterBlock count={metadata?.totalUsersWithFullCollection} />
      <GiftBlock
        showElement={eventIsActive || (isCollectorType && gameIsActive && completed)}
        type={type}
        metadata={metadata}
      />
      <MasterOfLuck
        showElement={!eventIsActive && isLuckyType && completed}
        metadata={metadata}
        userNames={userNames}
      />
      <GiftButton type={type} completed={completed} rewardUrl={rewardUrl} eventIsActive={eventIsActive} />
      {(!eventIsActive || !gameIsActive) && isCollectorType && <WinnersOfLuck />}
    </div>
  );
};

export default Achievement;
