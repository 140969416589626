import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { QRCodeScanStatuses } from '../consts';
import { setShowQuizModal } from '../store/showQuizModal';
import {
  Card,
  CardDetails,
  Dashboard,
  GameDetails,
  QRCodeActivationResult,
  QuizAnswerBody,
  QuizAnswerData,
  QuizQuestionData,
  TapsCount,
  UserDetails,
} from '../types';
import { BASE_URL } from './constants';
import { CARDS } from '../database/cards';
import { ACHIEVEMENTS } from '../database/achievements';
import { cardLuckScore, usedQRs } from '../utils';

const api = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 600,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');

      headers.set('Authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  tagTypes: ['Card'],
  endpoints: (builder) => ({
    getAllCards: builder.query<Card[], void>({
      // query: () => 'user-cards',
      queryFn: async () => {
        const mockData: Card[] = CARDS.map((card) => {
          return {
            card,
            unlocked: card.id <= 19,
          };
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        return { data: mockData };
      },
    }),
    getDashboardInfo: builder.query<Dashboard, void>({
      // query: () => 'statistics',
      // transformErrorResponse: (error: FetchBaseQueryError): void => {
      //   if (error.status === 500) {
      //     window.location.href = '/error';
      //   }
      // },
      // keepUnusedDataFor: 0,
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 300));

        return { data: { totalLuck: '123456', totalUsers: 789 } };
      },
    }),
    getGameDetails: builder.query<GameDetails, void>({
      // query: () => 'game-details',
      // keepUnusedDataFor: 0,
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 300));

        return {
          data: { eventDaysLeft: 10, gameDaysLeft: 30 },
        };
      },
    }),
    getUserDetails: builder.query<UserDetails, void>({
      // query: () => 'user-details',
      // keepUnusedDataFor: 0,
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 300));

        return {
          data: {
            phoneNumber: '+3809912345678',
            totalCardsNumber: 28,
            cardsCollected: 19,
            achievements: ACHIEVEMENTS,
          },
        };
      },
    }),
    getCardDetails: builder.query<CardDetails, string>({
      // query: (id) => `user-cards/${encodeURIComponent(id)}`,
      queryFn: async (id: string) => {
        const { getCardLuckScore } = cardLuckScore(id);
        await new Promise((resolve) => setTimeout(resolve, 300));

        return {
          data: {
            luckScore: String(getCardLuckScore()),
          },
        };
      },
      // providesTags: (result, error, arg) => [{ type: 'Card', id: arg }],
      // transformErrorResponse: (error: FetchBaseQueryError): void => {
      //   if (error.status === 500) {
      //     window.location.href = '/error';
      //   }
      // },
    }),
    activateQRCode: builder.mutation<QRCodeActivationResult, string>({
      queryFn: async (code) => {
        const { getUsedQRs, setUsedQRs } = usedQRs();
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const usedQRList = getUsedQRs();

        if (code?.length !== 19) {
          return {
            data: { cardId: null, status: QRCodeScanStatuses.failed },
          };
        }

        if (usedQRList.includes(code)) {
          return {
            data: { cardId: null, status: QRCodeScanStatuses.duplicated },
          };
        }

        setUsedQRs(code);

        return {
          data: {
            cardId: 1,
            status: QRCodeScanStatuses.luckUpgraded,
          },
        };
      },

      // query: (code) => ({
      //   url: `qr/${encodeURIComponent(code)}`,
      //   method: 'POST',
      // }),
      invalidatesTags: (result) => {
        if (result?.status === QRCodeScanStatuses.luckUpgraded) {
          return [{ type: 'Card', id: result.cardId as number }];
        }
        return [];
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: scanResult } = await queryFulfilled;
          const isNewCardAdded = scanResult.status === QRCodeScanStatuses.newCardAdded;
          const isAllCardsCollected = scanResult.status === QRCodeScanStatuses.allCardsCollected;
          const isLuckUpgraded = scanResult.status === QRCodeScanStatuses.luckUpgraded;

          if (isNewCardAdded || isLuckUpgraded || isAllCardsCollected) {
            dispatch(setShowQuizModal({ value: true, delay: 5000 }));
          }

          if (isNewCardAdded || isAllCardsCollected) {
            dispatch(
              api.util.updateQueryData('getAllCards', undefined, (draft) => {
                const affectedCardIndex = draft.findIndex(({ card: { id } }) => id === scanResult.cardId);
                draft[affectedCardIndex].unlocked = true;
              })
            );
          }
        } catch (e) {
          console.log('error', e);
        }
      },
    }),
    setTapCount: builder.mutation<TapsCount, TapsCount>({
      queryFn: async ({ id, tapsCount }) => {
        const { setCardLuckScore } = cardLuckScore(String(id));
        await new Promise((resolve) => setTimeout(resolve, 300));

        setCardLuckScore(tapsCount);

        return {
          data: {
            id,
            tapsCount,
          },
        };
      },
      // query: ({ id, tapsCount }) => ({
      //   url: `user-cards/${id}/process-taps`,
      //   method: 'PATCH',
      //   body: { tapsCount },
      // }),

      onQueryStarted: async ({ id: cardId }, { dispatch, queryFulfilled }): Promise<void> => {
        try {
          const { data } = await queryFulfilled;
          const { getCardLuckScore } = cardLuckScore(String(cardId));

          dispatch(
            api.util.updateQueryData('getCardDetails', cardId.toString(), (draft) => ({
              ...draft,
              // luckScore: (data as unknown as CardDetails).luckScore,
              luckScore: String(getCardLuckScore()),
            }))
          );
        } catch (e) {
          if ((e as FetchBaseQueryError).status === 500) {
            window.location.href = '/error';
          }

          console.log('error', e);
        }
      },
    }),
    getQuizQuestion: builder.query<QuizQuestionData, void>({
      query: () => 'questionnaire',
      keepUnusedDataFor: 0,
    }),
    sendQuizAnswer: builder.mutation<QuizAnswerData, QuizAnswerBody>({
      query: (body) => ({
        url: 'questionnaire',
        method: 'POST',
        body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.reward?.cardId) {
            dispatch(
              api.util.updateQueryData('getAllCards', undefined, (draft) => {
                const affectedCardIndex = draft.findIndex(({ card: { id } }) => id === data?.reward?.cardId);
                draft[affectedCardIndex].unlocked = true;
              })
            );
            dispatch(api.util.updateQueryData('getCardDetails', data?.reward?.cardId.toString(), (draft) => draft));
          }
        } catch (e) {
          console.log('error', e);
        }
      },
    }),
  }),
});

export default api;

export const {
  useGetAllCardsQuery,
  useGetCardDetailsQuery,
  useActivateQRCodeMutation,
  useSetTapCountMutation,
  useGetDashboardInfoQuery,
  useGetUserDetailsQuery,
  useGetGameDetailsQuery,
  useGetQuizQuestionQuery,
  useSendQuizAnswerMutation,
} = api;
