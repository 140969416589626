import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import styles from './BackButton.module.css';

const BackButton = (): ReactElement => {
  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(-1);
  };

  return (
    <div className={styles.buttonWrapper} onClick={handleBackClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <span className={styles.text}>назад</span>
    </div>
  );
};

export default BackButton;
