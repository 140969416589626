export enum CardTypes {
  epic = 'EPIC',
  legendary = 'LEGENDARY',
  basic = 'BASIC',
  happy = 'HAPPY',
}

export const routes = {
  cardsCollection: '/',
  settings: '/settings',
  privacyPolicies: '/privacyPolicy',
  termsOfUse: '/termsOfUse',
  cards: '/cards',
  dashboard: '/dashboard',
};

export enum QRCodeScanStatuses {
  newCardAdded = 'NEW_CARD_ADDED',
  luckUpgraded = 'LUCK_UPGRADED',
  allCardsCollected = 'ALL_CARDS_COLLECTED',
  failed = 'FAILED',
  duplicated = 'FAILED_DUE_DUPLICATE',
  overloaded = 'OVERLOADED',
}

export const QR_CODE_SCAN_ERROR_MESSAGE: string =
  'На жаль, при скануванні QR-коду сталася помилка. Будь ласка повторіть спробу відсканувати картку';

export const QR_CODE_SCAN_DUPLICATED_MESSAGE: string =
  'На жаль, цей QR-код вже було використано і повторне сканування неможливе.';

export const ALL_CARDS_COLLECTED_MESSAGE: string =
  'Ви зібрали всі 28 карток. Залишився останній крок, щоб отримати криптобонус від';

export const CAMERA_IS_NOT_ALLOWED: string =
  'Схоже що Ви обмежили доступ до камери вашого пристрою, будь ласка, переконайтеся, що застосунку АТБ надано доступ до камери на вашому пристрої, і перезапустіть застосунок';

export const GET_FIRST_PLAYER_CARD: string =
  'Купуйте колекційні або епічні картки у мережі магазинів АТБ та скануйте  QR код на картці для активації';

export const OVERLOADED_TEXT: string = 'Ти дуже гарячий вболівальник. Ми перегрілись. Зачекай трохи доки ми охолонемо.';

export const WHITE_BIT_LINKS = {
  eventInfo: 'https://whitebit.com', //https://whitebit.com/ua/events/uafwb2024',
  rules: 'https://whitebit.com', //'https://blog.whitebit.com/uk/collect-the-whole-team',
  support: 'https://t.me/+I8r33L-RRUk1NGMy',
};
