import { Card } from '../types';
import { CardTypes } from '../consts';

export const CARDS: Card['card'][] = [
  {
    id: 27,
    name: 'Олександр Караваєв',
    photoUrl: '27.jpg',
    position: 'Захисник',
    tshirtNumber: 0,
    type: CardTypes.basic,
  },
  {
    id: 25,
    name: 'Сергій Сидорчук',
    photoUrl: '25.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 5,
    type: CardTypes.basic,
  },
  {
    id: 28,
    name: 'Олексій Гуцуляк',
    photoUrl: '28.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 0,
    type: CardTypes.basic,
  },
  {
    id: 21,
    name: 'Олександр Тимчик',
    photoUrl: '21.jpg',
    position: 'Захисник',
    tshirtNumber: 21,
    type: CardTypes.basic,
  },
  {
    id: 20,
    name: 'Роман Яремчук',
    photoUrl: '20.jpg',
    position: 'Нападник',
    tshirtNumber: 9,
    type: CardTypes.basic,
  },
  {
    id: 19,
    name: 'Юхим Конопля',
    photoUrl: '19.jpg',
    position: 'Захисник',
    tshirtNumber: 2,
    type: CardTypes.basic,
  },
  {
    id: 18,
    name: 'Олександр Зубков',
    photoUrl: '18.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 20,
    type: CardTypes.basic,
  },
  {
    id: 17,
    name: 'Олександр Піхальонок',
    photoUrl: '17.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 0,
    type: CardTypes.basic,
  },
  {
    id: 23,
    name: 'Денис Попов',
    photoUrl: '23.jpg',
    position: 'Захисник',
    tshirtNumber: 0,
    type: CardTypes.basic,
  },
  {
    id: 22,
    name: 'Володимир Бражко',
    photoUrl: '22.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 14,
    type: CardTypes.basic,
  },
  {
    id: 14,
    name: 'Данило Сікан',
    photoUrl: '14.jpg',
    position: 'Нападник',
    tshirtNumber: 0,
    type: CardTypes.basic,
  },
  {
    id: 13,
    name: 'Георгій Бущан',
    photoUrl: '13.jpg',
    position: 'Воротар',
    tshirtNumber: 1,
    type: CardTypes.basic,
  },
  {
    id: 16,
    name: 'Валерій Бондар',
    photoUrl: '16.jpg',
    position: 'Захисник',
    tshirtNumber: 19,
    type: CardTypes.basic,
  },
  {
    id: 15,
    name: 'Микола Шапаренко',
    photoUrl: '15.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 6,
    type: CardTypes.basic,
  },
  {
    id: 12,
    name: 'Владислав Ванат',
    photoUrl: '12.jpg',
    position: 'Нападник',
    tshirtNumber: 18,
    type: CardTypes.basic,
  },
  {
    id: 10,
    name: 'Андрій Лунін',
    photoUrl: '10.jpg',
    position: 'Воротар',
    tshirtNumber: 23,
    type: CardTypes.legendary,
  },
  {
    id: 6,
    name: 'Віктор Циганков',
    photoUrl: '6.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 15,
    type: CardTypes.legendary,
  },
  {
    id: 5,
    name: 'Віталій Миколенко',
    photoUrl: '5.jpg',
    position: 'Захисник',
    tshirtNumber: 16,
    type: CardTypes.legendary,
  },
  {
    id: 4,
    name: 'Ілля Забарний',
    photoUrl: '4.jpg',
    position: 'Захисник',
    tshirtNumber: 13,
    type: CardTypes.legendary,
  },
  {
    id: 3,
    name: 'Артем Довбик',
    photoUrl: '3.jpg',
    position: 'Нападник',
    tshirtNumber: 11,
    type: CardTypes.epic,
  },
  {
    id: 8,
    name: 'Анатолій Трубін',
    photoUrl: '8.jpg',
    position: 'Воротар',
    tshirtNumber: 12,
    type: CardTypes.legendary,
  },
  {
    id: 2,
    name: 'Михайло Мудрик',
    photoUrl: '2.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 10,
    type: CardTypes.epic,
  },
  {
    id: 1,
    name: 'Олександр Зінченко',
    photoUrl: '1.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 17,
    type: CardTypes.epic,
  },
  {
    id: 9,
    name: 'Микола Матвієнко',
    photoUrl: '9.jpg',
    position: 'Захисник',
    tshirtNumber: 22,
    type: CardTypes.legendary,
  },
  {
    id: 7,
    name: 'Георгій Судаков',
    photoUrl: '7.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 7,
    type: CardTypes.legendary,
  },
  {
    id: 26,
    name: 'Максим Таловєров',
    photoUrl: '26.jpg',
    position: 'Захисник',
    tshirtNumber: 4,
    type: CardTypes.basic,
  },
  {
    id: 11,
    name: 'Руслан Маліновський',
    photoUrl: '11.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 8,
    type: CardTypes.legendary,
  },
  {
    id: 24,
    name: 'Богдан Михайліченко',
    photoUrl: '24.jpg',
    position: 'Захисник',
    tshirtNumber: 3,
    type: CardTypes.basic,
  },
  {
    id: 29,
    name: 'Андрій Ярмоленко',
    photoUrl: '29.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 0,
    type: CardTypes.happy,
  },
  {
    id: 30,
    name: 'Тарас Степаненко',
    photoUrl: '30.jpg',
    position: 'Пiвзахисник',
    tshirtNumber: 0,
    type: CardTypes.happy,
  },
  {
    id: 31,
    name: 'Олександр Сваток',
    photoUrl: '31.jpg',
    position: 'Захисник',
    tshirtNumber: 0,
    type: CardTypes.happy,
  },
];
