import { Achievement, AchievementTypes } from '../types';

export const ACHIEVEMENTS: Achievement[] = [
  {
    description: 'Збери всі 28 карток з колекції та отримай свій приз',
    title: 'Колекціонер',
    type: AchievementTypes.ALL_CARDS_COLLECTED,
    metadata: {
      cardId: 1,
      totalUsersWithFullCollection: 2,
      totalReward: 100000,
    },
  },
  {
    description: 'Збери найбільшу кількість удачі конкретному гравцю та отримай 1000 USDT',
    title: 'Майстер удачі',
    type: AchievementTypes.GREATEST_LUCK_COLLECTED,
  },
];
